<template>
  <div class="card">
    <div class="p-1">
      <h3 class="font-weight-bolder font-medium-3">ตั้งค่าสมัครสมาชิก
      </h3>
    </div>
    <div class="table-responsive mb-0">
      <div class="table-striped table-responsive-sm">
        <table
          id="__BVID__641"
          role="table"
          aria-busy="false"
          aria-colcount="8"
          class="table b-table"
        >
          <thead
            role="rowgroup"
            class="thead-light"
          >
            <tr
              role="row"
              class=""
            >
              <th
                role="columnheader"
                scope="col"
                aria-colindex="1"
                class="text-center"
              >
                <div>ลำดับ</div>
              </th><th
                role="columnheader"
                scope="col"
                aria-colindex="2"
                class=""
              >
                <div>ชื่อเข้าใช้งาน</div>
              </th><th
                role="columnheader"
                scope="col"
                aria-colindex="3"
                class=""
              >
                <div>ชื่อ สกุล</div>
              </th><th
                role="columnheader"
                scope="col"
                aria-colindex="4"
                class="text-center"
              >
                <div>ระงับเมื่อ</div>
              </th><th
                role="columnheader"
                scope="col"
                aria-colindex="5"
                class=""
              >
                <div>ระงับโดย</div>
              </th><th
                role="columnheader"
                scope="col"
                aria-colindex="6"
                class=""
              >
                <div>หมายเหตุ</div>
              </th><th
                role="columnheader"
                scope="col"
                aria-colindex="7"
                class=""
              >
                <div>รายละเอียด</div>
              </th><th
                role="columnheader"
                scope="col"
                aria-colindex="8"
                class=""
              >
                <div>#</div>
              </th>
            </tr>
          </thead><tbody role="rowgroup">
            <tr
              role="row"
              aria-rowindex="1"
              class=""
            >
              <td
                aria-colindex="1"
                role="cell"
                class="text-center"
              >
                1
              </td><td
                aria-colindex="2"
                role="cell"
                class=""
              >
                newnew01
              </td><td
                aria-colindex="3"
                role="cell"
                class=""
              >
                <span>new 01</span>
              </td><td
                aria-colindex="4"
                role="cell"
                class="text-center"
              >
                <span>08/02/2022<br> 09:33:08</span>
              </td><td
                aria-colindex="5"
                role="cell"
                class=""
              >
                System
              </td><td
                aria-colindex="6"
                role="cell"
                class=""
              >
                Login
              </td><td
                aria-colindex="7"
                role="cell"
                class=""
              >
                Password incorrect more than
              </td><td
                aria-colindex="8"
                role="cell"
                class=""
              >
                <button
                  type="button"
                  class="btn btn-danger btn-sm"
                >
                  <div class="d-flex align-items-center">
                    <span class="font-weight-lighter"><i class="fas fa-ban" /> ยกเลิกระงับ</span>
                  </div>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
